import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", tag: "section" } },
    [
      _c(
        "p",
        {
          staticStyle: {
            "font-style": "normal",
            "font-weight": "bold",
            "font-size": "25px",
            "line-height": "35px",
            "margin-bottom": "0px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("allocation_requests")) + " ")]
      ),
      _c(
        "span",
        {
          staticStyle: {
            "font-weight": "300",
            "font-size": "15px",
            "line-height": "35px",
            color: "#9c9c9c",
          },
        },
        [_vm._v(_vm._s(_vm.$t("allocation_requests_desc")))]
      ),
      _c(
        "div",
        { staticClass: "dxa-expand-container mt-2" },
        [
          _c(
            "a",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "end",
                "align-items": "center",
              },
              on: {
                click: function ($event) {
                  _vm.summaryExpanded = !_vm.summaryExpanded
                },
              },
            },
            [
              _c(
                "p",
                { staticClass: "mb-0", staticStyle: { color: "#9c9c9c" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.summaryExpanded ? "close_summary" : "open_summary"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                VIcon,
                { staticClass: "ml-2", attrs: { color: "primary" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.summaryExpanded
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(VExpandTransition, [
            _vm.summaryExpanded
              ? _c(
                  "div",
                  [
                    _vm.summaryLoading
                      ? _c(
                          VRow,
                          { attrs: { justify: "center" } },
                          [
                            _c(VProgressCircular, {
                              attrs: {
                                indeterminate: "",
                                size: "15",
                                color: "primary",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          VRow,
                          {
                            attrs: {
                              align: "center",
                              justify: "space-between",
                            },
                          },
                          [
                            _c(
                              VCol,
                              { attrs: { cols: "12", sm: "12", md: "6" } },
                              [
                                _c("div", { staticClass: "info-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-3",
                                      staticStyle: { width: "100%" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "deposited-text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("total_deposited")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        {
                                          attrs: {
                                            align: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "summary-total-deposited",
                                            },
                                            _vm._l(
                                              _vm.summary.totalDeposited,
                                              function (item, i) {
                                                return _c("div", { key: i }, [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "value-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatToCurrency(
                                                                  item.currency,
                                                                  item.totalDeposited
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "pct-text ml-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.totalDepositedPercentage.toFixed(
                                                                  2
                                                                )
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                          _c(VSpacer),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "dxa-btn-secondary ma-0",
                                                                class: {
                                                                  "download-btn-mobile":
                                                                    _vm.gs.isMobile(),
                                                                },
                                                                attrs: {
                                                                  loading:
                                                                    _vm.followUpReportDownloading,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.download_follow_up_report()
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-file-download-outline"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "summary"
                                                                )
                                                              )
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3098050320
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("download"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _vm._l(_vm.signedInfo, function (info, i) {
                              return _c(
                                VCol,
                                {
                                  key: i,
                                  attrs: { cols: "12", sm: "6", md: "2" },
                                },
                                [
                                  _c("div", { staticClass: "info-box" }, [
                                    _c("div", [
                                      _c(
                                        "p",
                                        { staticClass: "signed-info-text" },
                                        [_vm._v(_vm._s(info.infoText))]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "signed-quantity-text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.summary[info.objectLabel]
                                                  .signed
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "signed-pending-text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("value_pending", {
                                                  value:
                                                    _vm.summary[
                                                      info.objectLabel
                                                    ].pending,
                                                })
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        VTabs,
        {
          staticClass: "dxa-tabs mt-4",
          attrs: {
            "show-arrows": "",
            grow: "",
            centered: "",
            "background-color": "transparent",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.tab_options, function (item, index) {
          return _c(
            VTab,
            {
              key: index,
              on: {
                click: function ($event) {
                  return _vm.index_changed(item.index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "dxa-card mt-3", attrs: { id: "interest-table" } },
        [
          _c("AllocationsTable", {
            attrs: { Tab: _vm.tab },
            on: {
              update: function ($event) {
                return _vm.get_follow_up_summary()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }