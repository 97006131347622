import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "d-flex",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.get_allocations_by_page()
            },
          },
        },
        [
          _c(VTextField, {
            staticClass: "mx-auto",
            staticStyle: { width: "100%", "max-width": "500px" },
            attrs: {
              label: _vm.$t("search"),
              "append-icon": "mdi-magnify",
              filled: "",
              dense: "",
              "hide-details": "",
            },
            on: { "click:append": _vm.get_allocations_by_page },
            model: {
              value: _vm.searchFilter,
              callback: function ($$v) {
                _vm.searchFilter = $$v
              },
              expression: "searchFilter",
            },
          }),
          _c(VTextField, {
            staticClass: "mx-auto",
            staticStyle: { "max-width": "240px" },
            attrs: {
              filled: "",
              "hide-details": "",
              label: _vm.$tc("range_date", 1),
              type: "date",
            },
            model: {
              value: _vm.initialDate,
              callback: function ($$v) {
                _vm.initialDate = $$v
              },
              expression: "initialDate",
            },
          }),
          _c(VTextField, {
            staticClass: "mx-auto",
            staticStyle: { "max-width": "240px" },
            attrs: {
              "hide-details": "",
              filled: "",
              label: _vm.$tc("range_date", 2),
              type: "date",
            },
            model: {
              value: _vm.finalDate,
              callback: function ($$v) {
                _vm.finalDate = $$v
              },
              expression: "finalDate",
            },
          }),
          _c(
            VBtn,
            {
              staticClass: "dxa-btn-icon-success",
              staticStyle: { "background-color": "transparent" },
              on: { click: _vm.get_allocations_by_page },
            },
            [_vm._v(_vm._s(_vm.$t("apply")))]
          ),
          _c(
            VBtn,
            {
              staticClass: "dxa-btn-icon-success",
              staticStyle: { "background-color": "transparent" },
              on: { click: _vm.cleanFilter },
            },
            [
              _c(VIcon, [_vm._v("mdi-trash-can-outline")]),
              _vm._v(_vm._s(_vm.$t("clean"))),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            VRow,
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "20", color: "primary" },
              }),
            ],
            1
          )
        : _vm.allocations.length == 0
        ? _c("div", [
            _c("p", { staticClass: "not-found text-center my-10" }, [
              _vm._v(" " + _vm._s(_vm.$t("allocation_not_found")) + " "),
            ]),
          ])
        : _c(
            "div",
            [
              !_vm.gs.isMobile()
                ? _c(VSimpleTable, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "pl-6" }, [
                          _vm._v(_vm._s(_vm.$tc("investor", 1))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("date"))),
                        ]),
                        _c("th", { staticClass: "pl-6" }, [
                          _vm._v(_vm._s(_vm.$t("value"))),
                        ]),
                        _c("th", { staticClass: "pl-6" }, [
                          _vm._v(_vm._s(_vm.$t("pendencies"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("last_follow_up_date"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("actions"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.allocations, function (n, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _vm._v(_vm._s(_vm.gs.show_full_name(n.User))),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.gs.convert_date(n.Timestamp, false)
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(n.Currency, n.Value))
                            ),
                          ]),
                          _c(
                            "td",
                            _vm._l(_vm.get_pendencies(n), function (p, i) {
                              return _c(
                                "p",
                                {
                                  key: i,
                                  staticClass: "my-1",
                                  staticStyle: { "font-size": "13px" },
                                },
                                [_vm._v(" " + _vm._s(p) + " ")]
                              )
                            }),
                            0
                          ),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.gs.convert_date(n.LastFollowUp, false)
                                ) +
                                " "
                            ),
                          ]),
                          _vm.Status == 0
                            ? _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "dxa-btn-icon-success",
                                                        staticStyle: {
                                                          "margin-right":
                                                            "12px !important",
                                                        },
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.manage_click(
                                                              n,
                                                              0
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-check")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("confirm"))),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "dxa-btn-icon-success",
                                                        staticStyle: {
                                                          "margin-right":
                                                            "12px !important",
                                                        },
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.manage_click(
                                                              n,
                                                              1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-pencil")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("edit"))),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "dxa-btn-icon-danger",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.manage_click(
                                                              n,
                                                              2
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-close")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("decline"))),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "dxa-btn-secondary",
                                      staticStyle: {
                                        "margin-right": "12px !important",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.manage_click(n, null)
                                        },
                                      },
                                    },
                                    [
                                      _c(VIcon, { staticClass: "mr-1" }, [
                                        _vm._v("mdi-text-box-outline"),
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("detail"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticClass: "my-2 text-center",
                          staticStyle: { color: "#9c9c9c" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("click_edit")) + " ")]
                      ),
                      _vm._l(_vm.allocations, function (n, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "mobile-request" },
                          [
                            _c(
                              "a",
                              {
                                staticStyle: { display: "flex" },
                                on: {
                                  click: function ($event) {
                                    return _vm.manage_click(n, null)
                                  },
                                },
                              },
                              [
                                _c(
                                  VCol,
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "start",
                                    },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-weight": "300",
                                            "font-size": "12px",
                                            "line-height": "15px",
                                            "letter-spacing": "0.15px",
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gs.convert_date(
                                                  n.Timestamp,
                                                  true
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-style": "normal",
                                            "font-weight": "normal",
                                            "font-size": "16px",
                                            "line-height": "20px",
                                            "letter-spacing": "0.15px",
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(n.Company.Name) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "font-weight": "300",
                                            "font-size": "12px",
                                            "line-height": "15px",
                                            "letter-spacing": "0.15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gs.show_full_name(n.User)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  VCol,
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "end",
                                      "align-items": "center",
                                    },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-style": "normal",
                                          "font-weight": "bold",
                                          "font-size": "16px",
                                          "line-height": "20px",
                                          "text-align": "center",
                                          "letter-spacing": "0.15px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatCurrency(
                                                n.Currency,
                                                n.Value
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
              _c(VPagination, {
                attrs: { color: "primary", length: _vm.total_pages },
                on: { input: _vm.changePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
      _vm.manageModal && !_vm.gs.isMobile()
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.manageModal,
                callback: function ($$v) {
                  _vm.manageModal = $$v
                },
                expression: "manageModal",
              },
            },
            [
              _c("ManageAllocationModal", {
                attrs: { Allocation: _vm.allocation, Option: _vm.manageOption },
                on: { close: _vm.reset_modal, update: _vm.update_allocation },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.manageModal && _vm.gs.isMobile()
        ? _c(
            VBottomSheet,
            {
              attrs: { scrollable: "" },
              model: {
                value: _vm.manageModal,
                callback: function ($$v) {
                  _vm.manageModal = $$v
                },
                expression: "manageModal",
              },
            },
            [
              _c("ManageAllocationModal", {
                attrs: { Allocation: _vm.allocation, Option: _vm.manageOption },
                on: { close: _vm.reset_modal, update: _vm.update_allocation },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }